import { environment } from './../../environment/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Project, ProjectPagination } from 'app/models/project';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private baseUrl = environment.apiUrl + '/projects';

    private _pagination: BehaviorSubject<ProjectPagination | null> = new BehaviorSubject(null);
    private _project: BehaviorSubject<Project | null> = new BehaviorSubject(null);
    private _projects: BehaviorSubject<Project[] | null> = new BehaviorSubject(null);


    constructor(private http: HttpClient, private _authService: AuthService) {
        // this.headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        // this.headers.set('Content-Type', 'application/json; charset=utf-8');
    }

    private getHeaderWithToken(){
        var headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        headers.set('Content-Type', 'application/json; charset=utf-8');
        
        headers.set('Authorization', `Bearer ${this._authService.accessToken}`);
        
        return headers;
    }

    /**
       * Getter for pagination
       */
    get pagination$(): Observable<ProjectPagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for project
     */
    get project$(): Observable<Project> {
        return this._project.asObservable();
    }

    /**
     * Getter for projects
     */
    get projects$(): Observable<Project[]> {
        return this._projects.asObservable();
    }


    /**
       * Get projects
       *
       *
       * @param page
       * @param size
       * @param sort
       * @param order
       * @param search
       */
    getProjects(page: number = 0, size: number = 10, sort: string = 'project_name', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
        Observable<{ pagination: ProjectPagination; projects: Project[] }> {
        return this.http.get<{ pagination: ProjectPagination; projects: Project[] }>(
            this.baseUrl, {
            params: {
                page: '' + page,
                size: '' + size,
                sort,
                order,
                search
            },
            headers: this.getHeaderWithToken()

        }).pipe(
            tap((response) => {
                console.log(response)
                this._pagination.next(response.pagination);
                this._projects.next(response.projects);
            })
        );
    }

    /**
    * Get product by id
    */
    getProjectById(id: string): Observable<Project> {
        return this._projects.pipe(
            take(1),
            map((projects) => {
                const project = projects.find(item => item._id === id) || null;
                this._project.next(project);
                return project;
            }),
            switchMap((project) => {

                if (!project) {
                    return throwError('Could not found project with id of ' + id + '!');
                }

                return of(project);
            })
        );
    }


    // getProjectCustomerById(id: string): Observable<Project> {
    // }

    /**
    * Create project
    */
    createProject(): Observable<Project> {
        // this.http.get<Project>(this.baseUrl, {headers : this.headers}).subscribe(
        //     res => console.log("MIAOOOOO")
        // )
        return this.projects$.pipe(
            take(1),
            switchMap(projects => this.http.post<Project>(this.baseUrl, { headers: this.getHeaderWithToken() }).pipe(
                map((newProject) => {
                    this._projects.next([newProject, ...projects]);
                    return newProject;
                })
            ))
        );
    }



    /**
       * Update project
       *
       * @param id
       * @param project
       */
    updateProject(id: string, project: Project): Observable<Project> {
        return this.projects$.pipe(
            take(1),
            switchMap(projects => this.http.patch<Project>(
                this.baseUrl, {
                params: {
                    id,
                    project
                },
                headers: this.getHeaderWithToken()
            }).pipe(
                map((updatedProject) => {
                    const index = projects.findIndex(item => item._id === id);
                    projects[index] = updatedProject;
                    this._projects.next(projects);
                    return updatedProject;
                }),
                switchMap(updatedProject => this.project$.pipe(
                    take(1),
                    filter(item => item && item._id === id),
                    tap(() => {
                        this._project.next(updatedProject);
                        return updatedProject;
                    })
                ))
            ))
        );
    }


    /**
       * Delete the product
       *
       * @param id
       */
    deleteProject(projectId: string): Observable<boolean> {
        return this.projects$.pipe(
            take(1),
            switchMap(projects => this.http.delete(this.baseUrl, {
                params: { projectId }
            }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted product
                    const index = projects.findIndex(item => item._id === projectId);

                    // Delete the product
                    projects.splice(index, 1);

                    // Update the projects
                    this._projects.next(projects);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
}
