import { environment } from './../../environment/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Flight, FlightPagination } from 'app/models/flight';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FlightService {
  private baseUrl = environment.apiUrl + '/flights';

  private _pagination: BehaviorSubject<FlightPagination | null> = new BehaviorSubject(null);
  private _flight: BehaviorSubject<Flight | null> = new BehaviorSubject(null);
  private _flights: BehaviorSubject<Flight[] | null> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private _authService: AuthService
    ) {
    // this.headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    // this.headers.set('Content-Type', 'application/json; charset=utf-8');
    // this.headers.set('Content-Type', 'application/json; charset=utf-8');
  }

  private getHeaderWithToken(){
    var headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    headers.set('Content-Type', 'application/json; charset=utf-8');
    
    headers.set('Authorization', `Bearer ${this._authService.accessToken}`);
    
    return headers;
  }

  /**
     * Getter for pagination
     */
  get pagination$(): Observable<FlightPagination> {
    return this._pagination.asObservable();
  }

  /**
   * Getter for flight
   */
  get flight$(): Observable<Flight> {
    return this._flight.asObservable();
  }

  /**
   * Getter for flights
   */
  get flights$(): Observable<Flight[]> {
    return this._flights.asObservable();
  }


  /**
     * Get flights
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
  getFlights(page: number = 0, size: number = 10, sort: string = 'flight_name', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
    Observable<{ pagination: FlightPagination; flights: Flight[] }> {
      console.log(size)
    return this.http.get<{ pagination: FlightPagination; flights: Flight[] }>(
      this.baseUrl, {
      params: {
        page: '' + page,
        size: '' + size,
        sort,
        order,
        search
      },
      headers: this.getHeaderWithToken()

    }).pipe(
      tap((response) => {
        console.log(response)
        this._pagination.next(response.pagination);
        this._flights.next(response.flights);
      })
    );
  }

  /**
  * Get product by id
  */
  getFlightByProjectName(name: string): Observable<Flight> {
    console.log("getFlightByProjectName ----->")
    return this.getFlights(0, 10000).pipe(
      switchMap(flights => this._getFlightByProjectName(name))
    );
  }

  /**
  * Get product by id
  */
  getFlightById(id: string): Observable<Flight> {
    console.log("getFlightByID ----->")
    return this.getFlights(0, 10000).pipe(
      switchMap(flights => this._getFlightById(id))
    );



    // console.log("----- geFlightByID ------  "+id)
    // if(this._flights){
    //   console.log("ID found")  
    //   return this._getFlightById(id)
    // }
    // else{
    //   console.log("ID not found!!!!!!")
    //   this.getFlights().subscribe(
    //     (flights) => {
    //       return this._getFlightById(id)
    //     }
    //   )
    // }
  }

  getTraceFileByFlightId(id: string){
    return this.http.get<File>(this.baseUrl + "/trace_file/"+id, { headers: this.getHeaderWithToken() })
  }

  private _getFlightByProjectName(name: string): Observable<Flight> {
    name = decodeURIComponent(name)
    return this._flights.pipe(
      take(1),
      map((flights) => {
        const flight = flights.find(item => item.project === name) || null;
        this._flight.next(flight);
        return flight;
      }),
      switchMap((flight) => {

        if (!flight) {
          return throwError('Could not found flight with nproject name ' + name + '!');
        }

        return of(flight);
      })
    );
  }
  

  private _getFlightById(id: string): Observable<Flight> {
    return this._flights.pipe(
      take(1),
      map((flights) => {
        const flight = flights.find(item => item._id === id) || null;
        this._flight.next(flight);
        return flight;
      }),
      switchMap((flight) => {

        if (!flight) {
          return throwError('Could not found flight with id of ' + id + '!');
        }

        return of(flight);
      })
    );
  }
  /**
  * Create flight
  */
  createFlight(): Observable<Flight> {
    // this.http.get<Flight>(this.baseUrl, {headers : this.headers}).subscribe(
    //     res => console.log("MIAOOOOO")
    // )
    return this.flights$.pipe(
      take(1),
      switchMap(flights => this.http.post<Flight>(this.baseUrl, { headers: this.getHeaderWithToken() }).pipe(
        map((newFlight) => {
          this._flights.next([newFlight, ...flights]);
          return newFlight;
        })
      ))
    );
  }



  //   const formData: FormData = new FormData();
  //   formData.append('trace_file', file, file.name);
  //   formData.append('filename', filename);
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Accept', 'application/json');

  //   return this.http.post(this.apiUrl, formData, { headers: headers });

  /**
     * Update flight
     *
     * @param id
     * @param flight
     */
  updateFlight(id: string, flight: Flight): Observable<Flight> {
    //console.log("Update: " + id)
    //console.log(flight)
    const formData: FormData = new FormData();
    formData.append('_id', flight._id);
    formData.append('flight_name', flight.flight_name);
    formData.append('project', flight.project);
    formData.append('trace_file', flight.trace_file);
    formData.append('trace_file_name', flight.trace_file_name);
    formData.append('yt_video_url', flight.yt_video_url);
    formData.append('data_volo', flight.data_volo);
    formData.append('lunghezza_volo', String(flight.lunghezza_volo));
    formData.append('label_pozzetto', String(flight.label_pozzetto));
    formData.append('lat_gps_pozzetto', String(flight.lat_gps_pozzetto));
    formData.append('long_gps_pozzetto', String(flight.long_gps_pozzetto));
    formData.append('status', String(flight.status));
    formData.append('status_coord', String(flight.status_coord));

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.flights$.pipe(
      take(1),
      switchMap(flights => this.http.patch<Flight>(
        this.baseUrl,
        formData, 
        { headers: headers }
      ).pipe(
        map((updatedFlight) => {
          const index = flights.findIndex(item => item._id === id);
          flights[index] = updatedFlight;
          this._flights.next(flights);
          return updatedFlight;
        }),
        switchMap(updatedFlight => this.flight$.pipe(
          take(1),
          filter(item => item && item._id === id),
          tap(() => {
            this._flight.next(updatedFlight);
            return updatedFlight;
          })
        ))
      ))
    );
  }


  /**
     * Delete the product
     *
     * @param id
     */
  deleteFlight(flightId: string): Observable<boolean> {
    return this.flights$.pipe(
      take(1),
      switchMap(flights => this.http.delete(this.baseUrl, {
        params: { flightId }
      }).pipe(
        map((isDeleted: boolean) => {

          // Find the index of the deleted product
          const index = flights.findIndex(item => item._id === flightId);

          // Delete the product
          flights.splice(index, 1);

          // Update the flights
          this._flights.next(flights);

          // Return the deleted status
          return isDeleted;
        })
      ))
    );
  }
}
